exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-antykoncepcja-tsx": () => import("./../../../src/pages/antykoncepcja.tsx" /* webpackChunkName: "component---src-pages-antykoncepcja-tsx" */),
  "component---src-pages-apps-mail-verified-tsx": () => import("./../../../src/pages/apps/mail-verified.tsx" /* webpackChunkName: "component---src-pages-apps-mail-verified-tsx" */),
  "component---src-pages-cennik-badan-tsx": () => import("./../../../src/pages/cennik-badan.tsx" /* webpackChunkName: "component---src-pages-cennik-badan-tsx" */),
  "component---src-pages-deklaracja-nfz-brak-ubezpieczenia-tsx": () => import("./../../../src/pages/deklaracja-nfz/brak-ubezpieczenia.tsx" /* webpackChunkName: "component---src-pages-deklaracja-nfz-brak-ubezpieczenia-tsx" */),
  "component---src-pages-deklaracja-nfz-index-tsx": () => import("./../../../src/pages/deklaracja-nfz/index.tsx" /* webpackChunkName: "component---src-pages-deklaracja-nfz-index-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jak-dojechac-tsx": () => import("./../../../src/pages/jak-dojechac.tsx" /* webpackChunkName: "component---src-pages-jak-dojechac-tsx" */),
  "component---src-pages-jak-podpisac-deklaracje-tsx": () => import("./../../../src/pages/jak-podpisac-deklaracje.tsx" /* webpackChunkName: "component---src-pages-jak-podpisac-deklaracje-tsx" */),
  "component---src-pages-jak-przygotowac-sie-do-badan-laboratoryjnych-tsx": () => import("./../../../src/pages/jak-przygotowac-sie-do-badan-laboratoryjnych.tsx" /* webpackChunkName: "component---src-pages-jak-przygotowac-sie-do-badan-laboratoryjnych-tsx" */),
  "component---src-pages-jak-przygotowac-sie-do-badan-tsx": () => import("./../../../src/pages/jak-przygotowac-sie-do-badan.tsx" /* webpackChunkName: "component---src-pages-jak-przygotowac-sie-do-badan-tsx" */),
  "component---src-pages-lista-podmiotow-rodo-tsx": () => import("./../../../src/pages/lista-podmiotow-rodo.tsx" /* webpackChunkName: "component---src-pages-lista-podmiotow-rodo-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-punkty-pediatryczne-tsx": () => import("./../../../src/pages/punkty-pediatryczne.tsx" /* webpackChunkName: "component---src-pages-punkty-pediatryczne-tsx" */),
  "component---src-pages-register-confirm-email-tsx": () => import("./../../../src/pages/register/confirm-email.tsx" /* webpackChunkName: "component---src-pages-register-confirm-email-tsx" */),
  "component---src-pages-register-finish-tsx": () => import("./../../../src/pages/register/finish.tsx" /* webpackChunkName: "component---src-pages-register-finish-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-register-thank-you-tsx": () => import("./../../../src/pages/register/thank-you.tsx" /* webpackChunkName: "component---src-pages-register-thank-you-tsx" */),
  "component---src-pages-regulaminy-i-warunki-tsx": () => import("./../../../src/pages/regulaminy-i-warunki.tsx" /* webpackChunkName: "component---src-pages-regulaminy-i-warunki-tsx" */),
  "component---src-pages-reset-password-[token]-tsx": () => import("./../../../src/pages/reset_password/[token].tsx" /* webpackChunkName: "component---src-pages-reset-password-[token]-tsx" */),
  "component---src-pages-subscriptions-subscription-id-cancel-tsx": () => import("./../../../src/pages/subscriptions/[subscriptionId]/cancel.tsx" /* webpackChunkName: "component---src-pages-subscriptions-subscription-id-cancel-tsx" */),
  "component---src-pages-subscriptions-subscription-id-verify-tsx": () => import("./../../../src/pages/subscriptions/[subscriptionId]/verify.tsx" /* webpackChunkName: "component---src-pages-subscriptions-subscription-id-verify-tsx" */),
  "component---src-pages-szczepienia-covid-19-tsx": () => import("./../../../src/pages/szczepienia-covid-19.tsx" /* webpackChunkName: "component---src-pages-szczepienia-covid-19-tsx" */),
  "component---src-pages-usuniecie-konta-tsx": () => import("./../../../src/pages/usuniecie-konta.tsx" /* webpackChunkName: "component---src-pages-usuniecie-konta-tsx" */),
  "component---src-pages-welcome-[token]-tsx": () => import("./../../../src/pages/welcome/[token].tsx" /* webpackChunkName: "component---src-pages-welcome-[token]-tsx" */),
  "component---src-pages-zapisanie-sie-dodatkowe-info-tsx": () => import("./../../../src/pages/zapisanie-sie-dodatkowe-info.tsx" /* webpackChunkName: "component---src-pages-zapisanie-sie-dodatkowe-info-tsx" */),
  "component---src-pages-zglos-problem-tsx": () => import("./../../../src/pages/zglos-problem.tsx" /* webpackChunkName: "component---src-pages-zglos-problem-tsx" */)
}

