import { createTheme, responsiveFontSizes } from "@mui/material";


const theme = responsiveFontSizes(createTheme({
    breakpoints: {
      values: {
        xl: 1920,
        lg: 1200,
        md: 980,
        sm: 600,
        xs: 0
      }
    },
    palette: {
      primary: {
        main: "#025BB4",
        dark: "#025BB4",
        light: "#2388FD",
      },
      secondary: {
        main: "#FFAC1F",
        light: "#FFAC1F",
        dark: "#FFAC1F"
      },
      text: {
        primary: "#050505",
      },
      background: {
        default: "#F1F1F1",
      }
    },
    typography: {
      fontFamily: '"Noto Sans", Sans, sans-serif',
      h1: {
        fontSize: "3.5rem",
        lineHeight: 1.2,
        fontFamily: "Noto Serif",
        fontWeight: 700,
        '@media (max-width:980px)': {
          fontSize: "2rem"
        }
      },
      h2: {
        fontSize: "1.375rem",
        lineHeight: 1.68,
        fontWeight: 500
      },
      h3: {
        fontSize: "1.375rem",
        lineHeight: 1.68,
        fontWeight: 400
      },
      h4: {
        fontSize: "1rem",
        lineHeight: 1.6
      },
      h5: {
        fontSize: "0.8rem",
        lineHeight: 1.2
      },
      body2: {
        fontSize: "1.125rem",
        color: "#000000",
        lineHeight: 2
      },
      body1: {
        fontSize: "1.2rem"
      },
      caption: {
        fontSize: "0.8rem",
        lineHeight: 1
      }
    },
    mixins: {
      toolbar: {
        backgroundColor: "#F8F8F8",
        height: 80,
        maxHeight: 80
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            whiteSpace: "nowrap"
          },
          sizeLarge: {
            height: "3.75rem",
            padding: "0 2.75rem",
            fontSize: "1.125rem"
          },
          contained: {
            color: "#FFFFFF",
            boxShadow: "none",
            borderRadius: 40,
            ":hover": {
              // boxShadow: "none"
            }
          },
        }
      },
    }
}), {factor: 2});

export default theme;